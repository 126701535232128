import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
// import logo from "../../../images/nursingLogo.svg";
import logoText from "../../../images/textLogo.svg";

const NavHader = () => {
   const [toggle, setToggle] = useState(false);
   return (
      <div className="nav-header" style={{zIndex: "12"}}>
         <Link to="/" className="brand-logo">   {toggle ? (
          <img className="logo-abbr text-center ml-3" height={60} width={50} src={logoText} alt="" />
        ) : (
          //  <img className="logo-compact" src={logoText} alt="" />
          <img className="brand-title" height={70} width={100} src={logoText} alt="" />
        )}

            {/* <img className="logo-abbr" src={logoText} alt="" />
            <img className="logo-compact" src={logoText} alt="" />
            <img className="brand-title" src={logoText} alt="" /> */}
         </Link>

         <div className="nav-control" onClick={() => setToggle(!toggle)}>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
