import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { useDispatch } from "react-redux";
import { sendNotification } from "../components/Api's/Api";
import { notifyError, notifyTopRight } from "../components/common/Toaster";
export default function Notification(props) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const limit = 10;
  const [notification, setNotification] = useState([]);
  // const [type, setType] = useState("all");
  let errorsObj = { title: "", body: "", selected: [] };
  const [errors, setErrors] = useState(errorsObj);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [selected, setSelected] = useState("");

  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (title === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (body === "") {
      errorObj.body = "Body is Required";
      error = true;
    }
    // if (selected === "") {
    //   errorObj.selected = "Please select an user";
    //   error = true;
    // }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    sendNotification(title, body, selected)
      .then(() => {
        notifyTopRight("Sent Successfully.");
        setTitle("");
        setBody("");
        setSelected("");
        setLoader(false);
      })
      .catch((error) => {
        notifyError("Something went wrong please try again!");
        setLoader(false);
      });
  }

  return (
    <div>
      <PageTitle activeMenu="Push Notifications" motherMenu="Notifications" />
      <div className="col-lg-8 col-md-7 ">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">Add Details</h3>
          </div>

          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Title</strong>
              </label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title"
              />
              {errors.title && (
                <div className="text-danger fs-12">{errors.title}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Message</strong>
              </label>
              <textarea
                // type="text"
                rows={4}
                className="form-control"
                value={body}
                onChange={(e) => setBody(e.target.value)}
                placeholder="Message.."
              />
              {errors.body && (
                <div className="text-danger fs-12">{errors.body}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Send To</strong>
              </label>
              <select
                className="form-control"
                onChange={(e) => setSelected(e.target.value)}
              >
                <option hidden>Select..</option>
                <option value={"user"}>User</option>
                <option value={"brand"}>Brand</option>
              </select>

              {errors.selected && (
                <div className="text-danger fs-12">{errors.selected}</div>
              )}
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-secondary btn-block">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* {loader && <Spinner />} */}
    </div>
  );
}
