import axiosInstance from "../../../services/AxiosInstance";

// ===================getAPIS=============================
export async function dashboardApi() {
  const response = await axiosInstance.get(`admin/dashboard`);
  return response;
}

//===================user List api================
export async function userListApi(currentPage, limit, search) {
  const response = await axiosInstance.get(
    `admin/userList?page=${currentPage}&limit=${limit}`
  );
  return response;
}
//===================block/unblock User api================

export async function disableEnableUser(id) {
  const response = await axiosInstance.put(`admin/enableDisabledUser?_id=${id}`);
  return response;
}
//===================delete User api================

export async function deleteUser(id) {
  const response = await axiosInstance.delete(`admin/deleteUser?_id=${id}`);
  return response;
}
export async function brandUserList(currentPage, limit, search) {
  const response = await axiosInstance.get(
    `admin/brandList?page=${currentPage}&limit=${limit}`
  );
  return response;
}
//===================product List api================
export async function productList(currentPage, limit, search) {
  const response = await axiosInstance.get(
    `admin/productList?page=${currentPage}&limit=${limit}`
  );
  return response;
}
export async function deleteProduct(id) {
  const response = await axiosInstance.delete(`admin/deleteProduct?_id=${id}`);
  return response;
}

//===================catgory List api================
export async function categoryList(currentPage, limit, search) {
  const response = await axiosInstance.get(
    `admin/categoryList?page=${currentPage}&limit=${limit}`
  );
  return response;
}
export async function deleteCategory(id) {
  const response = await axiosInstance.delete(`admin/deleteCategory?_id=${id}`);
  return response;
}
export async function postCategory(name) {
  const postData = {
    name,
  };
  const response = await axiosInstance.post(`admin/addCategory`, postData);
  return response;
}

//======================Put Api's=============================

//=========================Change password Api's==================
// export async function changePasswordApi(formData) {
//   const data = {
//     oldPassword: formData.oldPassword,
//     newPassword: formData.newPassword,
//   };
//   const response = await axiosInstance.put(`admin/auth/changePassword`, data);
//   return response;
// }

//======================Post Api's=============================

export async function sendNotification(title, message, userType) {
  const postData = {
    title,
    message,
    userType,
  };
  const response = await axiosInstance.post(
    `admin/sendNotifications`,
    postData
  );
  return response;
}
