import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import UserListingShimmerLoader from "./SkeletonPages/UserListingShimmerLoader";
import { deleteProduct, productList } from "../components/Api's/Api";
import emptyImg from "../../images/emptyImg.jpg";
import Pagination from "../components/common/Pagination";
import { notifyError, notifyTopRight } from "../components/common/Toaster";

export function ProductManagement(props) {
  const [usersList, setUsersList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const limit = 10;
  const [listLength, setListlenght] = useState("");
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  //=============fetch user List==================
  async function handleUserList() {
    setLoader(true);
    const res = await productList(currentPage, limit, search);
    setUsersList(res.data.result?.docs);

    const total = res.data.result.total;
    setPageCount(Math.ceil(total / limit));
    setListlenght(res.data.result.total);
    setLoader(false);
  }

  function onDelete(id) {
    setLoader(true);
    deleteProduct(id)
      .then((response) => {
        handleUserList();

        notifyTopRight(response.data.responseMessage);
        console.log(response);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "delete error");
        setLoader(false);
        notifyError(error?.response?.data?.responseMessage);
      });
  }
  useEffect(() => {
    handleUserList();
  }, [currentPage, search]);
  const [currentIndex, setCurrentIndex] = useState(0);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % usersList?.image?.length);
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);
  return (
    <div>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <h3 className="page-title"> Product Management </h3>
        <nav aria-label="">
          <ol className="d-flex ">
            <li className="breadcrumb-item">
              <a href="!#" onClick={(event) => event.preventDefault()}>
                Product
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              List
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th> image </th>

                      <th> Product Name </th>
                      <th> user Name </th>
                      <th> Category</th>

                      <th> Created </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  {loader ? (
                    <UserListingShimmerLoader count={10} />
                  ) : (
                    <tbody>
                      {usersList?.map((item) => (
                        <tr key={item?._id}>
                          <td
                            className="py-1"
                            onClick={() =>
                              props.history.push({
                                pathname: "/product-details",
                                state: item,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={item?.image ? item?.image[0]?.file : emptyImg}
                              height={80}
                              width={80}
                              alt="image"
                            />
                            {/* <img
                              src={
                                item.image[currentIndex]
                                  ? item.image[currentIndex]
                                  : emptyImg
                              }
                              height={80}
                              width={80}
                            /> */}
                          </td>

                          <td
                            onClick={() =>
                              props.history.push({
                                pathname: "/product-details",
                                state: item,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {item?.name}
                          </td>
                          <td
                            onClick={() =>
                              props.history.push({
                                pathname: "/product-details",
                                state: item,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {item?.userId?.name}
                          </td>
                          <td
                            onClick={() =>
                              props.history.push({
                                pathname: "/product-details",
                                state: item,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {item?.category?.name}
                          </td>

                          <td
                            onClick={() =>
                              props.history.push({
                                pathname: "/product-details",
                                state: item,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {moment(item?.createdAt).format("ll")}
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="warning light"
                                className="light sharp btn i-false"
                              >
                                {svg1}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {/* <Dropdown.Item
                                  onClick={() => {
                                    // variable.id = item._id;
                                    props.history.push("/user-details");
                                  }}
                                >
                                  View Details
                                </Dropdown.Item> */}
                                <Dropdown.Item
                                  onClick={() => {
                                    onDelete(item._id);
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {usersList?.length === 0 && !loader ? (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                ) : (
                  ""
                )}
                {usersList?.length === 0 ? (
                  ""
                ) : (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info ">
                        Showing {currentPage * limit + 1} to{" "}
                        {listLength > (currentPage + 1) * limit
                          ? (currentPage + 1) * limit
                          : listLength}{" "}
                        of {listLength} entries
                      </div>
                      <Pagination
                        pageCount={pageCount}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductManagement;
