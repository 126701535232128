import React, { useEffect, useState } from "react";
import ProfileSkeleton from "../pages/SkeletonPages/ProfileSkeleton";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import emptyImg from "../../images/emptyImg.jpg";
import moment from "moment";
export default function ProductDetails(props) {
  const data = props.location.state;
  console.log(data, "props datat");
  const [loader, setLoader] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data?.image?.length);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <div>
      <div>
        <div className="page-titles">
          <h4>Product Details</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="product-management">Product</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link>Product Details</Link>
            </li>
          </ol>
        </div>
        {loader ? (
          <ProfileSkeleton />
        ) : (
          <Card style={{ width: "70%" }}>
            <Card.Header>
              <div
                className="d-flex bio-user p-3"
                style={{ gap: "1rem", flexGrow: "1" }}
              >
                <div>
                  {/* <img
                    src={data?.image ? data?.image : emptyImg}
                    width={110}
                    height={110}
                    className="text-center img-profile"
                  /> */}
                  <img
                    src={
                      data.image[currentIndex]
                        ? data.image[currentIndex]?.file
                        : emptyImg
                    }
                    width={110}
                    height={110}
                    className="text-center img-profile"
                  />
                </div>
                <div className="text-left" style={{ flex: "1" }}>
                  <h3 className="text-color ">
                    {data?.name ? data?.name : "--"}
                  </h3>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>Name</h5>
                    <p>{data?.userId?.name ? data?.userId?.name : "--"}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>Email</h5>
                    <p>{data?.userId?.email ? data?.userId?.email : "--"}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>City</h5>
                    <p>{data?.userId?.city ? data?.userId?.city : "--"}</p>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="bio-user p-2 mb-2 d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Colos</h5>
                {data?.colors?.map((item) => (
                  <button
                    className="mt-2 ml-3"
                    style={{
                      backgroundColor: `${item}`,
                      width: "34px",
                      height: "34px",
                      borderRadius: "40px",
                      borderStyle: "none",
                    }}
                  />
                ))}
              </div>
              <div
                style={{
                  height: "1px",
                  marginTop: "px",
                  Width: "100%",
                  opacity: ".5",
                  backgroundColor: "#c6c9c9ee",
                }}
              ></div>
              <div className="text-black pt-2">
                <div className="d-flex justify-content-between align-items-center p-3 bio-user">
                  <h5 className="m-0">Age</h5>
                  <p className="m-0">
                    {data?.ageStart}-{data?.ageEnd}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center p-3 bio-user">
                  <h5 className="m-0">Category</h5>
                  <p className="m-0">{data?.category?.name}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center p-3 bio-user">
                  <h5 className="m-0">Price</h5>
                  <p className="m-0">
                    ${data?.priceStart}-${data?.priceEnd}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center p-3 bio-user">
                  <h5 className="m-0">Created At</h5>
                  <p className="m-0">{moment(data?.createdAt).format("ll")}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center p-3 mt-2 bio-user">
                  <h5 className="m-0">Occasion</h5>
                  <p className="m-0">{data?.occasion}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center p-3 mt-2 bio-user">
                  <h5 className="m-0">Weather</h5>
                  <p className="m-0">{data?.weather}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center p-3 mt-2 bio-user">
                  <h5 className="m-0">Style</h5>
                  <p className="m-0">{data?.style}</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        )}
      </div>
    </div>
  );
}
