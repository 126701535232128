import React, { useState,useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import emptyImg from "../../images/emptyImg.jpg";
import ProfileSkeleton from "../pages/SkeletonPages/ProfileSkeleton"
// import { viewUserDetails } from "../components/APIs/Api";
// import { variable } from "../components/Variable";


export default function UserDetail() {
  const [UserDetails,setUserDetails]=useState()
  const[loader ,setLoader]=useState(true)

//============userDetails================================
  // async function viewUserDetailsApi() {
  //   const res = await viewUserDetails(variable.id)
  //   setUserDetails(res)
  //     setLoding(false)
  
  // }
  
  //   useEffect(()=>{
  //     viewUserDetailsApi()
  //   },[])
   
  //   let item=UserDetails?.data?.data


  return (
    <>
      <div>
      <div className="page-titles">
        <h4>Users Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="user-management">Users</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Users Details</Link>
          </li>
        </ol>
      </div>
      {loader ? (<ProfileSkeleton />):(

        <Card style={{ width: "70%" }}>
          <Card.Header>
            <div
              className="d-flex bio-user p-3"
              style={{ gap: "1rem",flexGrow:"1"}}
            >
              <div>
                <img src={emptyImg} width={110} height={110} className="text-center img-profile" />
              </div>
              <div className="text-left"style={{flex:"1"}}>
                <h3 className="text-color ">{"nursing"}</h3>
                <div className="d-flex justify-content-between align-items-center"><h5>Email</h5>
                <p>{"abc@gmail.com"}</p></div>
                <div className="d-flex justify-content-between align-items-center">
                <h5 style={{margin:"0"}}>phone</h5>
                <p style={{margin:"0"}}><span>{"91"}</span>-{"0380778464"}</p>
                </div>
               
              </div>
            </div>
          </Card.Header>
          <Card.Body>
          <div className="bio-user">
                <h5 style={{margin:"0",padding:"15px 10px 0px 10px "}}>Bio</h5>
                <p style={{marginLeft:"10px",textAlign:"start"}}> <span>&#x2022; </span>I work with Bootstrap. When the user clicks a button, I want the input field to be filled automatically. So at the beginning it should be empty</p>
                </div>
                <div
                  style={{
                    height: "1px",
                    marginTop: "px",
                    Width: "100%",
                    opacity: ".5",
                    backgroundColor: "#c6c9c9ee",
                  }}
                ></div>
            <div className="text-black pt-2">
              <div className="d-flex justify-content-between align-items-center p-3 bio-user">
                <h5 className="m-0">Created At</h5>
                <p className="m-0">{"01/01/2013"}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center p-3 mt-2 bio-user">
                <h5 className="m-0">Address</h5>
                <p className="m-0">
                  {"chandighar"}
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
   </>
  );
}
