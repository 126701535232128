import React, { lazy, useState } from "react";
import { Link } from "react-router-dom";
/// Image
import profile from "../../../images/profile/pic1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
const ChangePassword = lazy(() =>
  import("../../components/Modal/ChangePassword")
);

const Header = ({ onNote }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="header" style={{ zIndex: "11" }}>
      {showModal && (
        <ChangePassword
          showModal={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left"></div>
            <ul className="navbar-nav header-right">
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="i-true c-pointer nav-link"
                  to=""
                  role="button"
                  data-toggle="dropdown"
                >
                  {/* <img src={profile} width={10} alt="" /> */}
                  <div className="header-info ml-2 ">
                    <span>
                      Hello, <strong className="">Admin</strong>
                    </span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="dropdown-menu dropdown-menu-right"
                >
                  {/* <Link
                    to="/app-profile"
                    className="dropdown-item ai-icon"
                    style={{ backgroundColor: "white", color: "black" }}
                  >
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="black"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ml-2">Profile </span>
                  </Link> */}
                  {/* <Link
                    className="dropdown-item ai-icon"
                    style={{ backgroundColor: "white", color: "black" }}
                  >
                    <span>
                      <i class="fa fa-edit"></i>
                    </span>
                    <span className="ml-2"> Edit </span>
                  </Link> */}
                  <Link
                    className="dropdown-item ai-icon"
                    onClick={() => setShowModal(true)}
                    style={{ backgroundColor: "white", color: "black" }}
                  >
                    <span>
                      <i class="fa fa-key"></i>
                    </span>
                    <span className="ml-2">Change Password </span>
                  </Link>
                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
