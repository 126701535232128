import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import UserListingShimmerLoader from "./SkeletonPages/UserListingShimmerLoader";
import {
  categoryList,
  deleteCategory,
  productList,
} from "../components/Api's/Api";
import emptyImg from "../../images/emptyImg.jpg";
import Pagination from "../components/common/Pagination";
import { notifyError, notifyTopRight } from "../components/common/Toaster";
import AddCategory from "../components/Modal/AddCategory";

export function CategoryManagement(props) {
  const [show, setShow] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const limit = 10;
  const [listLength, setListlenght] = useState("");
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  //=============fetch user List==================

  async function handleUserList() {
    setLoader(true);
    const res = await categoryList(currentPage, limit, search);
    setUsersList(res.data.result.docs);
    const total = res.data.result.total;
    setPageCount(Math.ceil(total / limit));
    setListlenght(res.data.result.total);
    setLoader(false);
  }

  //   console.log(usersList);
  ////////    function to delete one item  ////////////////
  const handleDelete = async (id) => {
    try {
      const response = await deleteCategory(id);
      console.log(response, "helllo");
      notifyTopRight(response.data.responseMessage);
      handleUserList();
    } catch (error) {
      notifyError(error?.response?.data?.responseMessage);
      console.log(error, "block user error ");
    }
  };

  useEffect(() => {
    handleUserList();
  }, [currentPage, search]);

  return (
    <div>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <h3 className="page-title">Category Management </h3>
        <nav aria-label="">
          <ol className="d-flex ">
            <li className="breadcrumb-item">
              <a href="!#" onClick={(event) => event.preventDefault()}>
                Category
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              List
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-header justify-content-end">
              <button
                className="btn btn-secondary py-2 px-2"
                onClick={() => setShow(true)}
              >
                Add +
              </button>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th> Category</th>

                      <th> Created </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  {loader ? (
                    <UserListingShimmerLoader count={10} />
                  ) : (
                    <tbody>
                      {usersList?.map((item) => (
                        <tr key={item?._id}>
                          <td>{item?.name ? item?.name : "--"}</td>

                          <td>{moment(item?.createdAt).format("ll")}</td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="warning light"
                                className="light sharp btn i-false"
                              >
                                {svg1}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {/* <Dropdown.Item
                                  onClick={() => {
                                    // variable.id = item._id;
                                    props.history.push("/user-details");
                                  }}
                                >
                                  View Details
                                </Dropdown.Item> */}
                                <Dropdown.Item
                                  onClick={() => {
                                    handleDelete(item._id);
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {usersList?.length === 0 && !loader ? (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                ) : (
                  ""
                )}
                {usersList?.length === 0 ? (
                  ""
                ) : (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info ">
                        Showing {currentPage * limit + 1} to{" "}
                        {listLength > (currentPage + 1) * limit
                          ? (currentPage + 1) * limit
                          : listLength}{" "}
                        of {listLength} entries
                      </div>
                      <Pagination
                        pageCount={pageCount}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddCategory
        show={show}
        table={handleUserList}
        onHide={() => setShow(false)}
      />
    </div>
  );
}

export default CategoryManagement;
