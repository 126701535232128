import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `https://api.hued.info/api/v1/`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("userDetails");
    if (token) {
      config.headers["token"] =  token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("userDetails");
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);





// const axiosInstance = axios.create({
//   baseURL: `http://3.138.53.108:3002/api/v1/`,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// axiosInstance.interceptors.request.use(async (request) => {
//   let token = localStorage.getItem("userDetails");
//   request.headers.Authorization = `Bearer ${token}`;
//   console.log("intercepters");
//   return request;
// });

// axiosInstance.interceptors.response.use(
//   async (response) => {
//     return response;
//   },
//   (error) => {
//     console.log(error.response.status, "instance error ");
//     if (error.response && error.response.status === 401) {
//       localStorage.removeItem("userDetails");

//       window.location.href = "/login";
//     }
//     return Promise.reject(error);
//   }
// );
export default axiosInstance;