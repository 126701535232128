import React from "react";
import { Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const UserDetailsCardSkeleton = () => {
  return (
    <Card style={{ width: "70%" }}>
      <Card.Header>
        <div className="d-flex bio-user p-3" style={{ gap: "1rem", flexGrow: "1" }}>
          <div>
            <Skeleton width={110} height={110} />
          </div>
          <div className="text-left" style={{ flex: "1" }}>
            <h3 className="text-color ">
              <Skeleton width={200} height={30} />
            </h3>
            <div className="d-flex justify-content-between align-items-center">
              <h5>Email</h5>
              <p>
                <Skeleton width={200} height={20} />
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h5 style={{ margin: "0" }}>Phone</h5>
              <p style={{ margin: "0" }}>
                <Skeleton width={150} height={20} />
              </p>
            </div>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="bio-user p-1">
          <h5 style={{ margin: "0", padding: "15px 10px 0px 10px" }}>Bio</h5>
          <p style={{ marginLeft: "10px" }}>
            <Skeleton count={3} height={20} />
          </p>
        </div>
        <div
          style={{
            height: "1px",
            marginTop: "px",
            Width: "100%",
            opacity: ".5",
            backgroundColor: "#c6c9c9ee",
          }}
        ></div>
        <div className="text-black pt-2">
          <div className="d-flex justify-content-between align-items-center p-3 bio-user">
            <h5>Created At</h5>
            <p className="">
              <Skeleton width={150} height={20} />
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center p-3 mt-2 bio-user">
            <h5>Address</h5>
            <p className="">
              <Skeleton width={200} height={20} />
            </p>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default UserDetailsCardSkeleton;
