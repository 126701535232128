import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import Spinner from "../common/Spinner";
import { postCategory } from "../Api's/Api";
export default function AddCategory({ show, onHide, table }) {
  const [name, setName] = useState("");
  let errorsObj = { name: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [loader, setLoader] = useState(false);

  const handleSubmit = async () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (name.trim() === "") {
      errorObj.name = "This Field is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
   
    try {
      const response = await postCategory(
      name
      );
      console.log(response, "getresponse");
      table();
      onHide();
      notifyTopRight(response.data.responseMessage);
      setName("");
    } catch (error) {
      notifyError(error?.response?.data?.responseMessage);
      console.log(error, "get  error ");
    } finally {
      setLoader(false);
    }
  };
  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <form>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Add Content</h4>
            {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
          </div>
          {loader ? (
            <Spinner />
          ) : (
            <>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <span className="validation-text"></span>
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleSubmit}
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-secondary"
                >
                  Discard
                </button>
              </div>
            </>
          )}
        </form>
      </div>

      {/* {loader && <Spinner />} */}
    </Modal>
  );
}
